import React from 'react';

const Header = ({ children }: { children: any }) => {

    return (
        <React.Fragment>
            <div id="Header" className="flex justify-between items-center bg-primary text-white p-4">
                {children}
            </div>
        </React.Fragment>
    );
}

export default Header;