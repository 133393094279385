import React from 'react';


const Home = React.lazy(() => import('pages/Home'));
const TransForm = React.lazy(() => import('pages/TransForm'));

const Stats = React.lazy(() => import('pages/Stats'));
const StatsDetails = React.lazy(() => import('pages/StatsDetails'));

const Categories = React.lazy(() => import('pages/Categories'));
const CategoriesForm = React.lazy(() => import('pages/CategoriesForm'));

const Debts = React.lazy(() => import('pages/Debts'));
const DebtsForm = React.lazy(() => import('pages/DebtsForm'));

const routes = [

    { path: `/`, element: Home, },
    { path: `/form`, element: TransForm, },
    { path: `/form/:id`, element: TransForm, },

    { path: `/stats`, element: Stats, },
    { path: `/stats/:category`, element: StatsDetails, },

    { path: `/categories`, element: Categories, },
    { path: `/categories/:id`, element: CategoriesForm, },

    { path: `/debts`, element: Debts, },
    { path: `/debts/:id`, element: DebtsForm, },
];

export default routes;