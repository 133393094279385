import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Tabs = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const current = location.pathname.split('/')[1] || '';

    const tabs = [
        { code: '', name: 'Overview', icon: 'home_app_logo' },
        { code: 'stats', name: 'Stats', icon: 'data_usage' },
        { code: 'debts', name: 'Debts', icon: 'credit_score' },
        { code: 'categories', name: 'Categories', icon: 'layers' },
    ];

    return (
        <React.Fragment>
            <div id="Tabs" className="border border-gray-100 shadow-lg rounded-xl p-2 flex justify-between bg-white">
                {tabs.map(tab => (
                    <div
                        key={tab.code}
                        className={`tab flex items-center px-4 py-2 text-center ${current === tab.code ? 'font-semibold active primary rounded-full' : 'text-gray-600'}`}
                        onClick={() => navigate(`/${tab.code}`)}
                    >
                        <span className="material-symbols-outlined text-2xl">
                            {tab.icon}
                        </span>
                        {current === tab.code && (
                            <div className="text-xs ml-2">{tab.name}</div>
                        )}
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
}

export default Tabs;