import React from 'react';
import moment from 'moment';
import { func, types } from 'utils';
import { _store } from '_store';


interface Props {
    q?: string;
    row: types.Transaction;
    onClick?: () => void;
}
const Card = ({ q, row, onClick }: Props) => {

    const categories = _store.useState(e => e._data.categories);
    const ctg = categories.find(c => c.name.toLowerCase() === row.category.toLowerCase());
    const regex = new RegExp((q || 'alibababa'), 'gi');

    return (
        <React.Fragment>
            <div
                id="Card"
                className={`border border-gray-200 mt-2 shadow-mds shadow-gray-200s bg-white flex items-center rounded-lg p-2 mx-4 ${onClick ? 'hover:bg-gray-50' : ''}`}
                onClick={() => onClick && onClick()}
            >
                <div className="text-centers w-[14vw]">
                    <span className="material-symbols-outlined rounded-full bg-blue-100 p-2 primary">
                        {ctg?.icon || 'paid'}
                    </span>
                </div>
                <div className="w-[58vw]">
                    <div className="font-medium">{(ctg?.name || '')}</div>
                    <div className="text-gray-400 text-xs" dangerouslySetInnerHTML={{ __html: (row.notes).split('\n').join('<br />').replaceAll(regex, `<i class="bg-yellow-200">${q}</i>`) }} />
                </div>
                <div className="text-right w-[30vw]">
                    <div className={`font-medium ${row.type === 'expense' ? 'text-red-600' : 'text-green-600'}`}>
                        {row.type === 'expense' ? '-' : '+'} {func.format.number(row.amount)}
                    </div>
                    <div className="text-gray-400 text-xs">{moment(row.date).format('LL')}</div>
                </div>
            </div>
        </React.Fragment>
    );

};

export default Card;