import React from 'react';
import { Progress } from 'antd';
import { func, types } from 'utils';
import { _store } from '_store';


interface Props {
    category: string;
    total: number;
    onClick?: () => void;
}
const CardStat = ({ total, category, onClick }: Props) => {

    const categories = _store.useState(e => e._data.categories);
    const ctg = categories.find(c => c.name.toLowerCase() === category.toLowerCase()) as types.Category;
    const percent = (ctg?.budget && +ctg.budget > 0 ? ((total / +ctg.budget) * 100) : 0);

    return (
        <React.Fragment>
            <div
                id="CardStat"
                className={`border border-gray-200 mt-2 shadow-mds shadow-gray-200s bg-white rounded-lg p-2 mx-4 ${onClick ? 'hover:bg-gray-50' : ''}`}
                onClick={() => onClick && onClick()}
            >
                <div className="flex items-center">
                    <div className="text-centers w-[14vw]">
                        <span className="material-symbols-outlined rounded-full bg-blue-100 p-1 primary">
                            {ctg?.icon || 'paid'}
                        </span>
                    </div>
                    <div className="w-[58vw]">
                        <div className="font-mediums">{(category)}</div>
                        <div className="text-gray-400 text-xs">
                            {func.format.number(+ctg?.budget - total)} remaining
                        </div>
                    </div>
                    <div className="text-right font-medium w-[30vw]">
                        {func.format.number(total)}
                    </div>
                </div>
                <Progress percent={percent} status={percent >= 70 ? 'exception' : 'active'} size="small" showInfo={false} className="m-0" />
            </div>
        </React.Fragment>
    );

};

export default CardStat;