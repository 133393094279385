import React from 'react';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import { routes } from './utils';
import { Loading, Tabs } from 'components';

const App = () => {

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  const Layout = () => {
    return (
      <React.Fragment>
        <Outlet />
      </React.Fragment>
    );
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <BrowserRouter>
        <React.Suspense fallback={<Loading />}>
          <Routes>
            <Route element={<Layout />}>
              {routes.map(route => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={(
                    <route.element />
                  )}
                />
              ))}
            </Route>
          </Routes>
          <Tabs />
        </React.Suspense>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
