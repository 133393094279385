import React from 'react';

const Loading = () => {

    return (
        <React.Fragment>
            <div id="Loading" className="flex justify-center items-center bg-white bg-primary fixed text-white">
                <img src="/favicon.png" alt="xPans." className="animate__animated animate__rotateIn animate__infinite border shadow-lg rounded-full" style={{ height: '16vw' }} />
            </div>
        </React.Fragment>
    );
}

export default Loading;