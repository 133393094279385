import { Store } from 'pullstate';
import { func, types } from 'utils';

const user = (func.getStorageJson('user') || {});
const debts = (func.getStorageJson('debts') || []) as types.Debt[];
const categories = (func.getStorageJson('categories') || []) as types.Category[];
const transactions = (func.getStorageJson('transactions') || []) as types.Transaction[];
const stats_filter = (func.getStorageJson('stats_filter') || {}) as types.StatsFilter;

const initialState = {
    _auth: {
        user,
        token: '',
        isLoggedIn: true,
    },
    _data: {
        debts,
        categories,
        transactions,
        stats_filter,
    },
};

export const _store = new Store<types.StoreProps>(initialState);

export const _update = {
    auth: (key: keyof typeof initialState._auth, data: any) => {
        func.setStorageJson(key, data);
        _store.update(s => {
            // @ts-ignore
            s._auth[key] = data;
            if (key === 'user' && data.id && func.getStorage('token')) {
                s._auth.isLoggedIn = true;
            }
        });
    },
    data: (key: keyof typeof initialState._data, data: any) => {
        func.setStorageJson(key, data);
        _store.update(s => {
            s._data[key] = data;
        });
    },
}