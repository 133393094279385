import moment from 'moment';
import { func } from '.';
import { notification } from 'antd';
import axios from 'axios';

const KEY = 'TWC5bX4aFPXKxgnluv1ar9b6hulgMKt4';

export const apnData = (obj: any) => {
    const body = new FormData();
    for (var p in obj) {
        if (p === 'file') {
            body.append('file', obj[p]);
        } else {
            body.append(p, obj[p]);
        }
    }
    return body;
}

export const apiHeaders = (type: string = ''): any => {
    if (type === 'file') {
        return {
            'Accept': 'application/json',
        };
    } else {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };
    }
}

const axiosURL = (action: string) => {
    func.init();
    const empty = action.includes('http:') || action.includes('https:') ? true : false;
    const url = ((empty === false) ? func.config.apiURL[func.config.env as keyof typeof func.config.apiURL] + action : action);
    return url;
}

export const get = async (action: string, data: any = {}) => {
    data['cache'] = moment().unix();
    data['token'] = KEY;
    data['cache'] = moment().unix();
    data['apiKey'] = KEY;
    data['request'] = action;
    const headers = apiHeaders();
    // try {
    //     const response = await fetch(func.config.apiURL[func.config.env as keyof typeof func.config.apiURL] + '?' + new URLSearchParams(data).toString(), {
    //         method: 'GET',
    //         headers,
    //     });
    //     return await respunse(response);
    // } catch (error: any) {
    //     return { status: 606, message: 'Network request failed', data: error };
    // }
    try {
        const res = await axios({
            method: 'GET', url: func.config.apiURL[func.config.env as keyof typeof func.config.apiURL],
            headers,
            params: data,
        });
        return res.data;
    } catch (error: any) {
        return { status: 606, message: 'Network request failed', data: error };
    }
}

export const post = async (action: string, data: any = {}) => {
    data['cache'] = moment().unix();
    const headers = apiHeaders();
    try {
        const response = await fetch(axiosURL(action), {
            method: 'POST',
            headers,
            body: JSON.stringify(data),
        });
        return await respunse(response);
    } catch (error) {
        return { status: 606, message: 'Network request failed', data: error };
    }
}

export const put = async (action: string, data: any = {}) => {
    data['cache'] = moment().unix();
    const headers = apiHeaders();
    try {
        const response = await fetch(axiosURL(action), {
            method: 'PUT',
            headers,
            body: JSON.stringify(data),
        });
        return await respunse(response);
    } catch (error) {
        return { status: 606, message: 'Network request failed', data: error };
    }
}

export const patch = async (action: string, data: any = {}) => {
    data['cache'] = moment().unix();
    const headers = apiHeaders();
    try {
        const response = await fetch(axiosURL(action), {
            method: 'PATCH',
            headers,
            body: JSON.stringify(data),
        });
        return await respunse(response);
    } catch (error) {
        return { status: 606, message: 'Network request failed', data: error };
    }
}

export const delte = async (action: string, data: any = {}) => {
    data['cache'] = moment().unix();
    const headers = apiHeaders();
    try {
        const response = await fetch(axiosURL(action), {
            method: 'DELETE',
            headers,
            body: JSON.stringify(data),
        });
        return await respunse(response);
    } catch (error) {
        return { status: 606, message: 'Network request failed', data: error };
    }
}

export const postFile = async (action: string, data: any = {}) => {
    data['cache'] = moment().unix();
    const headers = apiHeaders('file');
    try {
        const response = await fetch(axiosURL(action), {
            method: 'POST',
            headers,
            body: apnData(data),
        });
        return await respunse(response);
    } catch (error) {
        return { status: 606, message: 'Network request failed', data: error };
    }
}

export const postDev = async (action: string, data: any = {}) => {
    data['cache'] = moment().unix();
    const headers = apiHeaders('dev');
    try {
        const response = await fetch(axiosURL(action), {
            method: 'POST',
            headers,
            body: JSON.stringify(data),
        });
        return await respunse(response);
    } catch (error) {
        return { status: 606, message: 'Network request failed', data: error };
    }
}

const respunse = async (response: Response) => {
    const res = await response.json();
    const status = response.status;
    if (status === 401 && func.getStorageJson('user').id) {
        signOutSuccess();
    }
    const message = Array.isArray(res) && ![201, 200].includes(status) ? res.join(", ") : (res.message || '');
    return { data: res, status, message };
}

export const signOutSuccess = (path = '/') => {
    notification.success({ message: 'Your token has expired' });
    func.delStorage('user');
    func.delStorage('token');
    setTimeout(() => {
        func.redirect(path);
    }, 300);
};