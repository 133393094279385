import React from 'react';
import { Progress } from 'antd';
import { func, types } from 'utils';


interface Props {
    row: types.Category;
    onClick?: () => void;
}
const CardCategory = ({ row, onClick }: Props) => {

    return (
        <React.Fragment>
            <div
                id="CardCategory"
                className={`border border-gray-200 mt-2 shadow-mds shadow-gray-200s bg-white rounded-lg p-2 mx-4 ${onClick ? 'hover:bg-gray-50' : ''}`}
                onClick={() => onClick && onClick()}
            >
                <div className="flex items-center">
                    <div className="text-centers w-[14vw]">
                        <span className="material-symbols-outlined rounded-full bg-blue-100 primary" style={{ padding: 6, fontSize: 20 }}>
                            {row.icon || 'paid'}
                        </span>
                    </div>
                    <div className="w-[58vw]">
                        <div className="font-mediums">{(row.name)}</div>
                    </div>
                    <div className="text-right font-medium w-[30vw]">
                        {func.format.number(row.budget)}
                    </div>
                </div>
                <Progress percent={30} size="small" showInfo={false} className="m-0 hidden" />
            </div>
        </React.Fragment>
    );

};

export default CardCategory;