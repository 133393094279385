import moment from 'moment-timezone';
// import * as pakage from '../../package.json';
import { parsePhoneNumber } from 'awesome-phonenumber';


export const config = {
    env: 'live',
    apiURL: {
        live: 'https://restapi.anchoratechs.com/xpans/',
        local: 'http://localhost/xpans/api/',
    },
    version: {
        apk: '1.0.0',
        ios: '1.0.0',
    },
}

export const init = () => {

}

export const app = {
    version: '3.0.0',
    dbpref: 'xpans_',
}

export const dates = {
    yr: moment().format('YYYY'),
    dt: moment().format('YYYY-MM-DD'),
}


// ::: storage
export const setStorage = (key: string, value: any) => {
    if (key) {
        localStorage.setItem(app.dbpref + key, value);
    }
}
export const getStorage = (key: string) => {
    const value = localStorage.getItem(app.dbpref + key);
    return value || '';
}
export const setStorageJson = (key: string, value: any, onOK: any = null) => {
    if (key && value) {
        localStorage.setItem(app.dbpref + key, JSON.stringify(value));
        onOK && onOK();
    }
}
export const getStorageJson = (key: string) => {
    if (key) {
        const value = localStorage.getItem(app.dbpref + key) || '';
        return isJson(value) ? JSON.parse(value) : '';
    }
}
export const delStorage = (key: string) => {
    if (key) {
        localStorage.removeItem(app.dbpref + key);
    }
}


export const inArray = (needle: any, haystack: any): boolean => {
    var length = haystack.length;
    for (var i = 0; i < length; i++) {
        if (haystack[i] === needle) return true;
    }
    return false;
}
export const mergeObj = (obj: any, src: any) => {
    for (var key in src) {
        if (src.hasOwnProperty(key)) obj[key] = src[key];
    }
    return obj;
}
export const getFileExtension = (filename: string) => {
    var ext = /^.+\.([^.]+)$/.exec(filename);
    return ext === null ? '' : ext[1];
}

export const redirect = (to: any) => {
    window.location = to;
}

export const generateOptions = (length: number, step = 1) => {
    const arr = [];
    for (let value = 0; value < length; value += step) {
        arr.push(value);
    }
    return arr;
};

export const format = {
    date: (date: string) => isToday(date) ? `Today @ ${moment(date).format('H:mm A')}` : moment(date).format('LLL'),
    number: (number: number, decimal = 2) => {
        number = number || 0;
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: decimal === 0 ? 0 : 2, maximumFractionDigits: decimal }).format(number);
    },
    currency: (amount: number | string) => `GH₵ ${format.number(+amount)}`,
    phoneNumber: (msisdn: string) => parsePhoneNumber(msisdn).number?.international || msisdn,
}

export const isToday = (date: string) => !!(moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'));

export const isJson = (str: any) => {
    try {
        JSON.parse(str);
        return true;
    } catch (error) {
        return false;
    }
}

export const togglePasswordShow = (e: any) => {
    var x = document.getElementById(e) as HTMLInputElement;
    if (x) {
        if (x.type === 'password') {
            x.setAttribute('type', 'text');
        } else {
            x.setAttribute('type', 'password');
        }
    }
}

export const ucFirst = (str: string) => {
    return str ? str.toLowerCase().charAt(0).toUpperCase() + str.toLowerCase().slice(1) : '';
}

export const ucWords = (str: string) => {
    return str ? str.split(' ').map(str => { return ucFirst(str); }).join(' ') : '';
}

export const chunk = (inputArray: any, chunkSize: number) => {
    return inputArray.reduce((resultArray: any, item: any, index: number) => {
        const chunkIndex = Math.floor(index / chunkSize);
        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []; // start a new chunk
        }
        resultArray[chunkIndex].push(item);
        return resultArray
    }, []);
}

// export const browser = async (link: string) => {
//     if (isMobile) {
//         await Browser.open({ url: link });
//     } else {
//         window.open(link, '_system');
//     }
// }

export const requiredField = [{ required: true, message: 'Field is required' }];

export const imgError = (e: any) => {
    e.target.src = '/favicon.png';
}

export const datesBetween = (startDate: string, endDate: string, format = 'YYYY-MM-DD') => {
    var dates: string[] = [];
    var currentDate: any = new Date(startDate);
    var addDays = function (this: any, days: number) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    };
    while (currentDate <= new Date(endDate)) {
        var date = moment(currentDate).format(format);
        if (!dates.includes(date)) {
            dates.push(date);
        }
        currentDate = addDays.call(currentDate, 1);
    }
    return dates;
}

export const sortString = (a: any, b: any, key: string) => {
    const fa = +(a[key] || 0);
    const fb = +(b[key] || 0);

    if (fa < fb) {
        return 1;
    }
    if (fa > fb) {
        return -1;
    }
    return 0;
}