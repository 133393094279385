import React from 'react';
import moment from 'moment';
import { func, types } from 'utils';


interface Props {
    row: types.Debt;
    onClick?: () => void;
}
const CardDebt = ({ row, onClick }: Props) => {

    return (
        <React.Fragment>
            <div
                id="CardDebt"
                className={`border border-gray-200 mt-2 shadow-mds shadow-gray-200s bg-white flex items-center rounded-lg p-2 mx-4 ${onClick ? 'hover:bg-gray-50' : ''}`}
                onClick={() => onClick && onClick()}
            >
                <div className="text-centers w-[14vw]">
                    <span className="rounded-full bg-blue-100 p-2 primary h-[30px] w-[30px]">
                        {row.person[0]}
                    </span>
                </div>
                <div className="w-[58vw]">
                    <div className="font-mediums">{row.person}</div>
                    <div className="text-gray-400 text-xs" dangerouslySetInnerHTML={{ __html: (row.notes).split('\n').join('<br />') }} />
                </div>
                <div className="text-right w-[30vw]">
                    <div className="font-medium">{func.format.number(row.amount)}</div>
                    <div className="text-gray-400 text-xs">{moment(row.date).format('LL')}</div>
                </div>
            </div>
        </React.Fragment>
    );

};

export default CardDebt;